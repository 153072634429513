<template>
	<div id="miniGoodsList">
		<page-goods-list :is-shop="0"></page-goods-list>
	</div>
</template>

<script>
import pageGoodsList from '@/components/layout/goods/page-goods-list.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{	
	name:'GoodsList',
	components:{
		pageGoodsList
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/goods/goodsEdit' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
	}
}
</script>

<style>
</style>
